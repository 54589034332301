<template>
    <div class="home">
        <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
        <el-container>
            <el-header class="home-header">
                <div class="logo-title">
                    <div>飞鱼商户管理系统</div>
                    <div @click="isCollapse = !isCollapse">
                        <span
                            :class="`iconfont ${
                                isCollapse ? 'icon-zhankai' : 'icon-shouqi'
                            }`"
                        ></span>
                    </div>
                </div>
                <div
                    style="
                        position: absolute;
                        right: 40px;
                        height: 60px;
                        line-height: 60px;
                    "
                    @click="passwordDialog = true"
                >
                    修改密码
                </div>
            </el-header>
            <el-container>
                <el-aside style="width: auto">
                    <el-menu
                        default-active="1"
                        :router="true"
                        :collapse-transition="true"
                        :unique-opened="true"
                        class="el-menu-vertical"
                        :style="`height: ${containerHeight - 60 + 'px'}`"
                        background-color="#545c64"
                        text-color="#fff"
                        active-text-color="#ffd04b"
                        @open="handleOpen"
                        @close="handleClose"
                        :collapse="isCollapse"
                    >
                        <el-menu-item index="/home/index">
                            <i class="iconfont icon-dingdan"></i>
                            <span slot="title">首页</span>
                        </el-menu-item>
                        <el-submenu index="goods">
                            <template slot="title">
                                <i class="iconfont icon-shangpinguanli"></i>
                                <span slot="title" style="padding-left: 4px"
                                    >商品</span
                                >
                            </template>
                            <el-menu-item index="/home/goods"
                                >商品列表</el-menu-item
                            >
                            <el-menu-item index="/home/goods/group"
                                >商品分组</el-menu-item
                            >
                        </el-submenu>
                        <el-menu-item index="/home/order">
                            <i class="iconfont icon-dingdan"></i>
                            <span slot="title">订单列表</span>
                        </el-menu-item>
                        <el-menu-item index="/home/rider">
                            <i class="iconfont icon-qishou"></i>
                            <span slot="title">骑手</span>
                        </el-menu-item>
                        <el-menu-item index="/home/configure">
                            <i class="iconfont icon-dingdan"></i>
                            <span slot="title">商户配置</span>
                        </el-menu-item>
                        <el-menu-item index="/home/printer">
                            <i class="iconfont icon-dingdan"></i>
                            <span slot="title">打印机</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-container>
                    <el-main
                        :style="`height: ${containerHeight - 74 + 'px'}`"
                        class="no-scroll"
                    >
                        <router-view />
                    </el-main>
                    <!-- <el-footer>Footer</el-footer> -->
                </el-container>
            </el-container>
        </el-container>

        <el-dialog
            :title="`修改密码`"
            class="add-dialog"
            :close-on-click-modal="false"
            width="400px"
            :visible.sync="passwordDialog"
        >
            <el-form :rules="rules" ref="addGoodsGroupForm" label-width="78px">
                <el-form-item label="原密码" prop="groupName">
                    <el-input
                        size="medium"
                        type="password"
                        v-model="password"
                    ></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="groupName">
                    <el-input
                        size="medium"
                        type="password"
                        v-model="new_password"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="passwordDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureChangePassword()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "Home",
    components: {},
    data() {
        return {
            isCollapse: false,
            containerHeight: 0,
            passwordDialog: false,
            password: "",
            new_password: "",
            rules: {
                // 定义验证规则
            },
        };
    },
    mounted() {
        this.containerHeight = document.body.clientHeight;
        console.log(this.containerHeight);
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        sureChangePassword() {
            if (!this.password.trim()) {
                this.$message.error("请输入原密码！");
                return;
            }
            if (!this.new_password.trim()) {
                this.$message.error("请输入新密码！");
                return;
            }
            this.axios
                .post("/user/set/password", {
                    password: this.password,
                    new_password: this.new_password,
                })
                .then((res) => {
                    this.$message.success("修改成功");
                    this.passwordDialog = false;
                });
        },
    },
};
</script>

<style lang="scss">
.home {
    background-color: #f0f2f5;

    .home-header {
        background-color: #ffffff;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        position: relative;

        .logo-title {
            display: flex;
            align-items: center;
            height: 100%;

            > div:first-child {
                flex-shrink: 0;
                width: 200px;
                font-size: 20px;
                font-weight: 600;
                text-align: center;
            }

            .iconfont {
                font-size: 24px;
            }
        }
    }

    .el-menu-vertical:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }

    .el-main {
        padding: 0;
        margin: 14px 26px 0;
        background-color: #ffffff;
        overflow-y: scroll;
    }

    .el-menu {
        .iconfont {
            font-size: 20px;
            width: 24px;
            display: inline-block;
        }
    }
    .el-menu-item {
        > span {
            display: inline-block;
            padding-left: 4px;
        }
    }

    .el-table {
        th {
            background-color: #f0f2f5 !important;
        }
    }
}
</style>
